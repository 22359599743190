// @mui material components
import React, { useState, useEffect } from 'react';
import MDBox from "components/MDBox";
import DashboardLayout from "pages/common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/common/Navbars/DashboardNavbar";
import RouteList from './routeList';
function Rotalar() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3}>
        <MDBox sx={{ width: '100%'}}>  
          <RouteList></RouteList>          
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default Rotalar;
