import { useRef, useState,useEffect } from 'react';
import {useJsApiLoader, GoogleMap,  Marker  } from '@react-google-maps/api';
    
function LocMap({center}) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  })
  
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  
  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef()

  useEffect(() => {
    let timer = setTimeout(() => {  }, 1000);  

    return () => clearTimeout(timer)
  }, []);
  
  const handleMapLoad = (map) => {
      setMap(map);
  }
  
  if (!isLoaded) {
    return "Is Loading ...";
  }  
  return (
    <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        onLoad={map => handleMapLoad(map)}
    >
      <Marker position={center} />
             
    </GoogleMap>
    )
  }  
  export default LocMap
  