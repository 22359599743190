// @mui material components
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "pages/common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/common/Navbars/DashboardNavbar";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {API_URL,Accordion,AccordionSummary,
  AccordionDetails,Item} from 'pages/common/constants';
import { useMaterialUIController } from "context";
import Swal from 'sweetalert2';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {aracTip} from './workFunctions';
import Step1 from './step1';

const steps = ['Rut Seç', 'Evrak Düzenle', 'Onayla'];
var araclar = []
var bolgeler = ['Tümü'];
var depolar = [];
var rutlar = {}
var filtered = []
var aracTipleri = []

var soforler = [{ Adi: "--- YARDIMCI YOK ---", kodu: "-1" }]

function bolgeEkle(bolge) {
  var returned = false;
  for (let i = 0; i < bolgeler.length; i++) {
    if (bolgeler[i] === bolge) {
      returned = true;
    }
  }
  if (returned === false) {
      bolgeler.push(bolge)
  }
}
function depoEkle(depo,enlem,boylam) {
  var returned = false;
  for (let i = 0; i < depolar.length; i++) {
    if (depolar[i].ad === depo) {
      returned = true;
    }
  }
  if (returned === false) {
    var obj = {}
    obj.ad = depo
    obj.enlem = enlem
    obj.boylam = boylam
    depolar.push(obj)
  }
}
function rutEkle(rut, hacim,kg) {
  var returned = false;
  Object.keys(rutlar).forEach(function (key) {
      if (rut === key) {
          returned = true;
      }
  });

  if (returned === false) {
      let obj = {}
      obj.miktar = 1
      obj.hacim = hacim
      obj.kg = kg
      rutlar[rut] = obj
  } else {
      rutlar[rut].miktar = rutlar[rut].miktar + 1
      rutlar[rut].hacim = rutlar[rut].hacim + hacim
      rutlar[rut].kg = rutlar[rut].kg + kg
  }
}

function aracTipEkle(aracTip, hacim, kg) {
  var returned = false;

  for (let i = 0; i < aracTipleri.length; i++) {      
      if (aracTipleri[i].image === aracTip) {
          returned = true;
      }
  }

  if (returned === false) {
      let obj = {}
      obj.image = aracTip
      obj.hacim = hacim
      obj.kg    = kg
      aracTipleri.push(obj)
  }
}

const sevkiyatAddress = API_URL + "/api/sevkiyat";
const aracAddress = API_URL + "/api/araclar";
const soforAddress = API_URL + "/api/sofor";

function Shipment() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [region, setRegion] = useState('Tümü');
  const [depo, setDepo] = useState('Tümü');
  const [ sevkiyatList, setSevkiyatList]= useState([]);
  const [ sevkedilecekler, setSevkedilecekler]= useState([]);
  const [hidden, setHidden] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [activeRut, setActiveRut] = useState();
    
  const disablePage = ()=>{
    setHidden(false);
    setActiveStep(0);
  }
  
  
  const handleChangeRegion = (event) => {
    setRegion(event.target.value);
    disablePage();
    
  }
  const handleChangeDepo = (event) => {
    setDepo(event.target.value);
    disablePage();
  }
  
  //------------------------------------------
  function getCariGroup(rut){
    let evraklar = []
    if (region === "Tümü" && depo === "Tümü") {
      for (let i = 0; i < sevkiyatList.length; i++) {
          if (sevkiyatList[i]['RUT'].includes(rut)) {
              evraklar.push(sevkiyatList[i])
          }
      }
    } else if (region === "Tümü") {
      for (let i = 0; i < sevkiyatList.length; i++) {
          if (sevkiyatList[i]['Depo'] === depo && sevkiyatList[i]['RUT'].includes(rut)) {
              evraklar.push(sevkiyatList[i])
          }
      }
    } else if (depo === "Tümü") {
      for (let i = 0; i < sevkiyatList.length; i++) {
          if (sevkiyatList[i]['bolge'] === region && sevkiyatList[i]['RUT'].includes(rut)) {
              evraklar.push(sevkiyatList[i])
          }
      }
    } else {
      for (let i = 0; i < sevkiyatList.length; i++) {
          if (sevkiyatList[i]['bolge'] === region && sevkiyatList[i]['Depo'] === depo && sevkiyatList[i]['RUT'].includes(rut)) {
              evraklar.push(sevkiyatList[i])
          }
      }
    }
    let cari_gruplu_evraklar = {}
    for (let k = 0; k < evraklar.length; k++) {
        try {
            cari_gruplu_evraklar[evraklar[k].CariKod].push(evraklar[k])
        } catch (err) {
            cari_gruplu_evraklar[evraklar[k].CariKod] = []
            cari_gruplu_evraklar[evraklar[k].CariKod].push(evraklar[k])
        }
    }
    return Object.keys(cari_gruplu_evraklar).length;
  }
  function Step0(){
    return(
      <MDTypography variant="span" sx={{ mt: 2, mb: 1 }}>
        {
          rutlar && Object.keys(rutlar).map((rut,index)=>(
            <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
              <AccordionSummary dark={darkMode ? 1 : 0} aria-controls="panel1d-content" id="panel1d-header">
                <MDTypography sx={{fontSize:14,fontWeight:500}}>{rut+' ('+getCariGroup(rut)+')'}</MDTypography>
              </AccordionSummary>
              <AccordionDetails dark={darkMode ? 1 : 0}>                                    
                {aracTipleri && aracTipleri.map((tip, index) => (
                  aracTip(rut,index,rutlar,aracTipleri)                                        
                  ))}
                <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 0 }}>                            
                  <MDBox sx={{ flex: '1 1 auto' }} />
                  <MDButton color="success" onClick={()=>evrakGetir(rut)}>
                    Evrak Düzenle
                  </MDButton>
                </MDBox>                                       
              </AccordionDetails>
            </Accordion>
          ))
        }
      </MDTypography>
    );
  }
  //------------------------------------------------------
  function soforUpsert() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title: 'sofor List' })
    };
    fetch(soforAddress, requestOptions)
        .then(response => response.json())
        .then(data => {
          for (let i = 0; i < data.data.length; i++) {
            let obj = {};
            obj.Adi = data.data[i].Adi;
            obj.kodu = data.data[i].kodu;
            soforler.push(obj);
          }
    });
  }
//------------------------------------------------------
  useEffect(() => {
    const aracRequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title: 'arc List' })
    };
    fetch(aracAddress, aracRequestOptions)
        .then(response => response.json())
        .then(data => {
          araclar = data.data
          aracTipleri = [];
          for (let i = 0; i < araclar.length; i++) {
            Object.keys(araclar[i]).forEach(function (key) {
              if (key.toLocaleLowerCase() === "aracctipi") {
                  aracTipEkle(araclar[i][key], araclar[i].Hacim, araclar[i].Kg)
              }
            });
          }
    });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title: 'Sevkiyat List' })
    };
    fetch(sevkiyatAddress, requestOptions)
        .then(response => response.json())
        .then(data => {
          setSevkiyatList(data.data);
          // console.log('*******',data.data);
          let sevkiyatList = data.data;
          for (let i = 0; i < sevkiyatList.length; i++) {
            Object.keys(sevkiyatList[i]).forEach(function (key) {
              if (key.toLocaleLowerCase() === "bolge") {
                bolgeEkle(sevkiyatList[i][key])
              } else if (key.toLocaleLowerCase() === "depo") {
                depoEkle(sevkiyatList[i][key],sevkiyatList[i].KaynakEnlem,sevkiyatList[i].KaynakBoylam)              
              }
            });
          }   
      }); 
      setLoaded(true) ;
  }, [loaded]);
  
  const handleRaporla = () => {
    if(region ==="" || depo ===""){
      Swal.fire({
        title: 'Dikkat!',
        text: 'Bölge ve depo seçmek zorunludur!',
        icon: 'error',
        confirmButtonText: 'OK'
      });      
    }
    else{
      //------------ Filtered Start -------------------------------
      filtered = [];
      if (region === "Tümü" && depo === "Tümü") {
        for (let i = 0; i < sevkiyatList.length; i++) {
          filtered.push(sevkiyatList[i])
        }
      } else if (region === "Tümü") {
        for (let i = 0; i < sevkiyatList.length; i++) {
          if (sevkiyatList[i]['Depo'] === depo) {
              filtered.push(sevkiyatList[i])
          }
        }
      } else if (depo === "Tümü") {
        for (let i = 0; i < sevkiyatList.length; i++) {
          if (sevkiyatList[i]['bolge'] === region) {
              filtered.push(sevkiyatList[i])
          }
        }
      } else {
        for (let i = 0; i < sevkiyatList.length; i++) {
            if (sevkiyatList[i]['bolge'] === region && sevkiyatList[i]['Depo'] === depo) {
                filtered.push(sevkiyatList[i])
            }
        }
      }
      rutlar = []
      for (let i = 0; i < filtered.length; i++) {
        //console.log('*****sevkiyatList*******',sevkiyatList[i]);
        Object.keys(sevkiyatList[i]).forEach(function (key) {
          if (key.toLocaleLowerCase() === "rut") {
            var bol = sevkiyatList[i][key].split(",")
            for (let j = 0; j < bol.length; j++) {
              rutEkle(bol[j], sevkiyatList[i].Hacim, sevkiyatList[i].Kg)
            }
          }
        });
      }     
      
      soforUpsert();
      setHidden(true);
     } 
  }
  //-----------------------------------------------------------

//--------------------------------Stepper ----------------------
  const [activeStep, setActiveStep] = useState(0);
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  

  const handleReset = () => {
    setActiveStep(0);
  };


const [expanded, setExpanded] = React.useState('panel1');

const handleChange = (panel) => (event, newExpanded) => {
  setExpanded(newExpanded ? panel : false);
  // console.log(rutlar);
};

//---------------------------------------------
function evrakGetir(rut) {    
  let evraklar = []
  if (region === "Tümü" && depo === "Tümü") {
      for (let i = 0; i < sevkiyatList.length; i++) {
          if (sevkiyatList[i]['RUT'].includes(rut)) {
              evraklar.push(sevkiyatList[i])
          }
      }
  } else if (region === "Tümü") {
      for (let i = 0; i < sevkiyatList.length; i++) {
          if (sevkiyatList[i]['Depo'] === depo && sevkiyatList[i]['RUT'].includes(rut)) {
              evraklar.push(sevkiyatList[i])
          }
      }
  } else if (depo === "Tümü") {
      for (let i = 0; i < sevkiyatList.length; i++) {
          if (sevkiyatList[i]['bolge'] === region && sevkiyatList[i]['RUT'].includes(rut)) {
              evraklar.push(sevkiyatList[i])
          }
      }
  } else {
      for (let i = 0; i < sevkiyatList.length; i++) {
          if (sevkiyatList[i]['bolge'] === region && sevkiyatList[i]['Depo'] === depo && sevkiyatList[i]['RUT'].includes(rut)) {
              evraklar.push(sevkiyatList[i])
          }
      }
  }

  setActiveRut(rut);  
  setSevkedilecekler(evraklar);  
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
    
}

const isLoading = bolgeler.length === 1;   


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3}>
        <MDBox sx={{ width: '100%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={3} md={5}>
              <Item dark={darkMode ? 1 : 0}>
                <FormControl id='frmRegion' fullWidth>
                  <InputLabel id="regions-select-label">
                  <MDTypography variant="body2" sx={{fontSize:14,fontWeight:600}} color="dark">Bölge</MDTypography></InputLabel>
                  <Select style={{ marginTop: 1, marginLeft: 1, width:'100%',height:'50px' }}
                    labelId="regions-select-label"
                    
                    id="regions-select"
                    value={region}
                    label="Bölge"
                    onChange={handleChangeRegion}
                  >                    
                    {isLoading ? <MDTypography sx={{fontSize:14,fontWeight:600}}>Loading...</MDTypography>
                  :
                    bolgeler.map((bolge,index)=>(
                      <MenuItem key= {index} value={bolge}><MDTypography sx={{fontSize:14,fontWeight:600}}>{bolge}</MDTypography></MenuItem>
                    ))
                    }                    
                  </Select>
                </FormControl>
              </Item>
            </Grid>
            <Grid item xs={4} sm={3} md={5}>
              <Item dark={darkMode ? 1 : 0}>
                <FormControl id='frmDepo' fullWidth>
                    <InputLabel id="depos-select-label">
                    <MDTypography variant="body2" sx={{fontSize:14,fontWeight:600}} color="dark">Depo</MDTypography>
                      </InputLabel>
                    <Select style={{ marginTop: 1, marginLeft: 1, width:'100%',height:'50px' }}
                      labelId="depos-select-label"
                      id="depos-select"
                      value={depo}
                      label="Depo"                      
                      onChange={handleChangeDepo}
                    >
                      <MenuItem value="Tümü"><MDTypography sx={{fontSize:14,fontWeight:600}}>Tümü</MDTypography></MenuItem>
                      {depolar.map((depo,index)=>(
                        <MenuItem key= {index} value={depo.ad}><MDTypography sx={{fontSize:14,fontWeight:600}}>{depo.ad}</MDTypography></MenuItem>
                      ))
                      }                    
                    </Select>
                </FormControl>
              </Item>
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
              <Item dark={darkMode ? 1 : 0}>
                <MDButton style={{ width:'100%',height:'50px' }} variant="contained" color="success" onClick={handleRaporla}>Rapola</MDButton>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Card>             
                <MDBox pt={3}>
                  {                     
                    hidden?                   
                    <MDBox mt={-3} sx={{ width: '100%' }}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => {
                          const stepProps = {};
                          const labelProps = {};                          
                          
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      {
                        activeStep === 0 ? ( 
                          <React.Fragment>
                            <Step0></Step0>                                                        
                          </React.Fragment>                          
                        ): (activeStep === 1 ? (
                        <React.Fragment>
                          <Step1 activeRut={activeRut} aracTipleri= {aracTipleri} araclar={araclar} rutlar={rutlar} soforler = {soforler} depolar={depolar} sevkedilecekler= {sevkedilecekler} ></Step1>
                          <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <MDButton
                              color="dark"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Geri
                            </MDButton>
                            <MDBox sx={{ flex: '1 1 auto' }} />
                            

                            <MDButton color="success" onClick={handleNext}>
                              Yükle
                            </MDButton>
                            </MDBox>
                        </React.Fragment>
                          ):(
                            <React.Fragment>
                              <MDTypography sx={{ mt: 2, mb: 1 }}>
                                Yükleme işlemleri Burada
                              </MDTypography>
                              <MDBox sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <MDBox sx={{ flex: '1 1 auto' }} />
                                <MDButton color='dark' onClick={handleReset}>Başlangıca Geri Dön</MDButton>
                              </MDBox>
                            </React.Fragment>
                          ))        
                      }
                    </MDBox>
                    :<></>                   
                  }                 
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>      
    </DashboardLayout>
  );
}

export default Shipment;
