import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


export const API_URL = "https://sevkiyat.sevtar.com:8082";

export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
}));
export const AccordionSummary = styled((props) => (    
    <MuiAccordionSummary sx={{backgroundColor: props.dark?'#1A2039' : '#fff'}}
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({   
    
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  

export const AccordionDetails = (props) => (
<MuiAccordionDetails
    sx={{
    backgroundColor: props.dark ? '#1A3039' : '#ccc',
    padding: 1,    
    borderTop: '1px solid rgba(0, 0, 0, .125)'
    }}
>
    {props.children}
</MuiAccordionDetails>
);
  
export const Item = (props) => (
    <Paper
      sx={{
        backgroundColor: props.dark ? '#1A2039' : '#fff',
        padding: 2,       
        textAlign: 'center',
      }}
    >
      {props.children}
    </Paper>
  );
  
export function separateComma(val) {
  // remove sign if negative
  var sign = 1;
  if (val < 0) {
    sign = -1;
    val = -val;
  }

  // trim the number decimal point if it exists
  let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();

  while (/(\d+)(\d{3})/.test(num.toString())) {
    // insert comma to 4th last position to the match number
    num = num.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }

  // add number after decimal point
  if (val.toString().includes('.')) {
    num = num + '.' + val.toString().split('.')[1];
  }

  // return result with - sign if negative
  return sign < 0 ? '-' + num : num;
}  

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));