import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import DataTableBodyCell from './DataTableBodyCell';
import DataTableHeadCell from './DataTableHeadCell';
import {API_URL} from 'pages/common/constants';

const STableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#999',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const STableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function createData(Kod, Ad, Tipi, Plaka) {
  return {
    Kod, Ad, Tipi, Plaka,
  };
}
 


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: 'Kod',
    numeric: false,
    disablePadding: true,
    label: 'Kod',
  },
  {
    id: 'Ad',
    numeric: false,
    disablePadding: true,
    label: 'Ad',
  },
  {
    id: 'Tipi',
    numeric: false,
    disablePadding: true,
    label: 'Tipi',
  },
  {
    id: 'Plaka',
    numeric: false,
    disablePadding: true,
    label: 'Plaka',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (    
    <TableBody>
      <TableRow >
        {headCells.map((headCell) => (
          <DataTableHeadCell
            key={headCell.id}
            align='center' 
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sorted={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </DataTableHeadCell>
        ))}
      </TableRow>
    </TableBody>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const url = API_URL + "/api/araclar";

export default function Cars() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');  
  const [page, setPage] = useState(0);  
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows,setRows]=useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };  
  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title: 'arc List' })
    };
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {   
          // console.log('len',data.data.length);                                 
          setRows([]);
          for(let i=0;i<data.data.length;i++){
            let obj = []
            Object.keys(data.data[i]).forEach(function (key) {obj.push(data.data[i][key]) });
            setRows(rows => [...rows, createData(obj[0],obj[1], obj[2], obj[3])]);            
          }
        });    
  }, []); 
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table            
            aria-labelledby="tableTitle"            
          >
            <EnhancedTableHead              
              order={order}              
              orderBy={orderBy}              
              onRequestSort={handleRequestSort}
              rowCount={rows.length}              
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {                                    
                  return (
                    <STableRow 
                      hover 
                      tabIndex={-1}
                      key={index}                      
                    >                      
                      <DataTableBodyCell align="center">{row.Kod}</DataTableBodyCell>
                      <DataTableBodyCell align="center">{row.Ad}</DataTableBodyCell>
                      <DataTableBodyCell align="center">{row.Tipi}</DataTableBodyCell>
                      <DataTableBodyCell align="center">{row.Plaka}</DataTableBodyCell>                      
                    </STableRow >
                  );
                })}
              {emptyRows > 0 && (
                <STableRow 
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <STableCell colSpan={6} />
                </STableRow >
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-toolbar": {
              backgroundColor: "rgba(100,100,100,0.5)"
            },
            ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
              fontWeight: "bold",
              color: "blue"
            }
          }}
          
        />
      </Paper>      
    </Box>
  );
}

