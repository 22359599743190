import React, { forwardRef,useRef,useState,useEffect } from "react";
import {Dialog, DialogActions,DialogContent,Slide} from '@mui/material';
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { useMaterialUIController } from "context";
import geojson from './tr-cities.json';
import { geoMercator, geoPath } from 'd3-geo';
import { select } from 'd3-selection';
import * as d3 from "d3";
import { createPath, distance } from './Path';
import './index.css';
import Swal from 'sweetalert2';

const width = 960;
const height = 500;
let dotscale = 5;
var cities = [];
var pathTSP = {};
var tempCities;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MapDialog = ({ toggle, onSave,activeItem,rowCoordinates}) => {  
  // console.log('row co',rowCoordinates);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;  
  //------------------------------------------------------------------------------------------
  let [distanceHistory, setDistanceHistory] = useState([]);
  let [stats, setStats] = useState({ visited: 0, shortestPath: undefined })
  const [overCity, setOverCity] = useState("Türkiye");
  const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms))
  let projection = geoMercator().fitExtent(
    [[0, 0], [width * 0.9, height * 0.9]],
    geojson
  );
  // console.log(projection(34,39))
  const path = geoPath().projection(projection);
  
  const componentRef = useRef();

  const drawCities = () => {
    tempCities =[];   
    cities.forEach(function(city) {
      // console.log('************',city["xy"])
      tempCities.push(city["xy"]);
    });  
    let svg = document.getElementById('svg2d');    
    d3.select(svg).selectAll('circle').data(tempCities).enter()
      .append('circle')
        .attr('cx', function (d) { return d[0]; })
        .attr('cy', function (d) { return d[1]; })
        .attr('r', dotscale)
        .style('fill', 'yellowgreen');
  }  
  useEffect(() => {
    //console.log('rowCoordinates[0].length Clicked',rowCoordinates[0].length);
    cities = []; 
    for(let i = 0; i < rowCoordinates.length; i++){        
      // console.log('******boylam****',rowCoordinates[0][i]);
      cities.push({ xy:projection([rowCoordinates[i].boylam,rowCoordinates[i].enlem]),
                    latlon:[rowCoordinates[i].boylam,rowCoordinates[i].enlem],
                    name:rowCoordinates[i].ad});        
    }
    // console.log(cities)
    let svg = document.getElementById('svg2d');      
    d3.select(svg).selectAll('circle').remove();
    d3.select(svg).selectAll('path.connection').remove();      
    //console.log('------------------')
    greedySolver();      
    setTimeout(function () {         
      drawTSPPaths(pathTSP);
      drawCities();
      findAllDistance(pathTSP);
    }, 1000);
    const intervalId = setInterval(() => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Yönerge bulundu.',
        showConfirmButton: false,
        timer: 1500
      })
      onSave(activeItem);
  }, 1000);

  // clean up after firing effect
  return () => clearInterval(intervalId);    
      
  }, []);

  const rotate = (arr, count = 1) => {
    return [...arr.slice(count, arr.length), ...arr.slice(0, count)];
  };
  function drawTSPPaths(ipath) {    
    let input_path = ipath.path;
    var paths =[];
    tempCities =[];   
    cities.forEach(function(city) {
      tempCities.push(city["xy"]);
    });
    paths.push([tempCities[0],tempCities[0]]);
    
    while(input_path[0]!==0){
      input_path = rotate(input_path, 1);
    }
    for(let i=0;i<input_path.length-1;i++){
      paths.push([tempCities[input_path[i]],tempCities[input_path[i+1]]]);
    }
    paths.push([tempCities[input_path[input_path.length-1]],tempCities[0]]);
    let svg = document.getElementById('svg2d');      
    d3.select(svg).selectAll('path.connection').remove();
    d3.select(svg).selectAll('path.connection').data(paths).enter()
      .append('path')
        .attr('d', function(d) {
          var dx = d[1][0] - d[0][0],
              dy = d[1][1] - d[0][1],
              dr = Math.sqrt(dx * dx + dy * dy);
          return "M" + d[0][0] + "," + d[0][1] + "A" + dr + "," + dr + " 0 0,1 " + d[1][0] + "," + d[1][1];
        })
        .attr('class', 'connection')
      .attr("marker-end", "url(#arrow)");      
  }

  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }    

  function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
  }
  function findAllDistance(ipath) {
    let input_path = ipath.path;
    while(input_path[0]!==0){
      input_path = rotate(input_path, 1);
    }
    // console.log(input_path);
    // console.log("latlon8888",cities[0]);
    // console.log('input_path',input_path);
    let all_distance = 0;
    activeItem.traj_points = [];
    activeItem.traj_points.push(cities[0]);
    for(let i=0;i<input_path.length-1;i++){
      activeItem.traj_points.push(cities[input_path[i+1]]);
      let distance = getDistanceFromLatLonInKm(cities[input_path[i]]["latlon"][0],cities[input_path[i]]["latlon"][1],
            cities[input_path[i+1]]["latlon"][0],cities[input_path[i+1]]["latlon"][1]);
      // console.log(distance);
      all_distance += distance;
    }
    activeItem.traj_points.push(cities[input_path[0]]);
    all_distance += getDistanceFromLatLonInKm(cities[input_path[input_path.length-1]]["latlon"][0],cities[input_path[input_path.length-1]]["latlon"][1],
                                                cities[0]["latlon"][0],cities[0]["latlon"][1]);
    //console.log('all distance',all_distance);
    activeItem.all_distance= all_distance.toFixed(2)+' km';
    // console.log('traj steps: ',activeItem.traj_points);
    
  }

  const greedySolver = async () => {    
    //console.log('citieis is array of 2d array',cities);
    tempCities =[];   
    cities.forEach(function(city) {
      //console.log(city["xy"])
      tempCities.push(city["xy"]);
    });

    const getClosestCity = (tempCities, idx, patha) => {
      let minDistance = Infinity;
      let minIdx = null;
      for (let i = 0; i < tempCities.length; i++) {
        if (patha.includes(i))
          continue;
        let d = distance(tempCities[i], tempCities[idx]);
        if (d < minDistance) {
          minDistance = d;
          minIdx = i;
        }
      }
      // console.log('closes city',minIdx);
      return minIdx;
    }

    setDistanceHistory(Array(tempCities.length));
    let minDistance = Infinity;
    for (let i = 0; i < tempCities.length; i++) {
      let steps = Array(tempCities.length).fill(-1);
      steps[0] = i;
      for (let j = 1; j < tempCities.length; j++) {
        let closestIdx = getClosestCity(tempCities, steps[j - 1], steps);
        steps[j] = closestIdx;
      }
      let pathTemp = createPath(tempCities.length, tempCities, steps);
      if (pathTemp.distance < minDistance) {
        minDistance = pathTemp.distance;
        // console.log('pathTemp',pathTemp)
        pathTSP= pathTemp;
      }
      let history = distanceHistory;
      history[i] = minDistance;
      setDistanceHistory(history);
      let s = stats;
      s['visited'] += 1;
      s['shortestPath'] = minDistance;
      setStats(s);     

      await wait(60);
    }    
  }
  
  return (
    <Dialog open={true} sx={{width:20}} TransitionComponent={Transition}
      PaperProps={{style: {background: darkMode ? "#032A5A" : "white"} }}>      
        <DialogContent>            
          <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
          >     
             
      <svg width={width} height={height} id="svg2d" ref={componentRef} >
        <defs>    
          <marker
            id="arrow"
            // markerUnits="strokeWidth"
            markerWidth="6"
            markerHeight="6"
            viewBox="0 -5 10 10"
            refX="15"
            refY="-1.5"
            orient="auto">
            <path d="M0,-5L10,0L0,5"></path>
          </marker>
        </defs>
        <g className="geojson-layer">
          {
            geojson.features.map((d,index) => (
              <path
                key={index}
                d={path(d)}
                fill="#eee"
                stroke="#0e1724"
                strokeWidth="1"
                strokeOpacity="0.5"
                onMouseEnter={(e) => {
                  setOverCity(d.properties.name);
                  select(e.target)
                    .attr('fill', '#dc3522')
                }}
                onMouseOut={(e) => {
                  setOverCity('Türkiye');
                  select(e.target)
                    .attr('fill', '#eee')
                }}
                onClick={(e) => {
                  //cities.push({xy:d3.pointer(e),latlon:projection.invert(d3.pointer(e)),name:d.properties.name});                  
                  // console.log('click point',d3.pointer(e));
                 // console.log('point inversion',projection.invert(d3.pointer(e)));                  
                  // var coords = projection.invert(d3.pointer(e));
                  // console.log('xy',projection([coords[0],coords[1]]));

                  // console.log('projection',projection.);
                  // console.log(d.properties.name);
                 // console.log(cities);
                //  drawCities();
                }}
              />
            ))
          }
        </g>
      </svg>      
    </div>
        </DialogContent>
        <DialogActions>
            <MDButton variant="gradient" color="success" onClick={() => onSave(activeItem)}>
                <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                    &nbsp;Kaydet
            </MDButton>
            <MDButton variant="gradient" color="warning" onClick={()=>{
                  activeItem.all_distance = 'Hesaplanmamış';
                  activeItem.traj_points= [];
                  toggle();
            }}>
                <Icon sx={{ fontWeight: "bold" }}>cancel</Icon>
                &nbsp;Vaz geç
            </MDButton>        
        </DialogActions>
    </Dialog>
  );
};

export default MapDialog;