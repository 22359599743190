import React,{useState,useEffect,useRef} from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import MDTypography from "components/MDTypography";
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import {StyledTableCell,StyledTableRow} from 'pages/common/constants';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import { Divider } from '@mui/material';
import Fab from '@mui/material/Fab';
import Swal from 'sweetalert2';
import Modal from "./trajectory";
import Map from "./map";
import MapReport from './mapReport';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TableVirtuoso } from 'react-virtuoso';

var rowCo = [];
var rowlatLotPlaces = [];

const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (<TableContainer component={Paper} {...props} ref={ref} />)),
    Table: (props) => (<Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed'}} />),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function GroupDataGrid(props) {
    const [data, setData] = useState([]);
    const [detailData, setDetailData] = useState([]);
    const [chunkArray,setChunkArray] = useState([]);
    const [selectedAll, setSelectedAll] = useState(true);
    const virtuoso = useRef(null);
    const [isLoading,setIsLoading] = useState(true);
    const [ showModal, setShowModal]= useState(false);
    const [ showMap, setShowMap]= useState(false);
    const [iswayPointshow,setIswayPointshow] = useState(false);
    const [allDistance,setAllDistance] = useState('');
    const [allDuration,setAllDuration] = useState('');
    const [ activeItem, setActiveItem]= useState({all_distance:'Hesaplanmamış',traj_points: []});
    const [ mapResult, setMapResult]= useState({all_distance:'Hesaplanmamış',traj_pairs: []});

    const {cari_gruplu_evraklar,groupEticket,startDepoCoordinate} = props;
    // console.log('groupEticket',groupEticket);
    
    const getData=()=>{
        const local_data=[];        
        const local_detail = [];
        for (const [key, value] of Object.entries(cari_gruplu_evraklar)) {            
            let toplam_hacim = 0;
            let toplam_kilo = 0;
            let toplam_adet = 0;
            let groupEticketValue=[];
            for (let k=0;k<groupEticket.length;k++){
                groupEticketValue[groupEticket[k]] = 0;                
            }            
            let sevkFactor = [];
            let factor = cari_gruplu_evraklar[key];
            let title = factor[0].CariKod + '-' + factor[0].CariUnvan +'-'+ factor[0].Adres;   
            let sevkItem;
            for (let i = 0; i < factor.length; i++) {
                toplam_hacim += parseFloat(factor[i].Hacim)
                toplam_kilo += parseFloat(factor[i].Kg)
                toplam_adet += factor[i].Miktar - factor[i].AracaYuklenenMiktar - factor[i].MusteriyeTeslimEdilenMiktar
                sevkItem = factor[i];
                sevkItem.sevk = (factor[i].Miktar - factor[i].AracaYuklenenMiktar - factor[i].MusteriyeTeslimEdilenMiktar);
                sevkFactor.push(sevkItem);
                groupEticketValue[factor[i].Grup] += factor[i].Miktar;
            }
            toplam_hacim = toplam_hacim.toFixed(0);
            toplam_kilo = toplam_kilo.toFixed(0);
            local_data.push({key,expanded:false,checked:true, title, 'volume':Number(toplam_hacim), 'weight':Number(toplam_kilo), 'count':Number(toplam_adet), groupEticketValue});
            local_detail.push(sevkFactor);
        }
        setData(local_data);
        setDetailData(local_detail);                
        setIsLoading(false);
      }
    
      useEffect(()=>{
        getData();
      },[cari_gruplu_evraklar]);

    const handleDetailCellChange = (index,row, count) => {
        let minNum = 0;
        let maxNum = row.Miktar - row.AracaYuklenenMiktar - row.MusteriyeTeslimEdilenMiktar;
        const sevkValue = Math.max(minNum, Math.min(maxNum, Number(count)));
        
        const updatedItem = detailData.find(item => item[0].CariKod === row.CariKod);
        for(let i=0;i<updatedItem.length;i++){
          if(updatedItem[i].StokKodu === row.StokKodu){
            // console.log(updatedItem[i]);
            updatedItem[i].sevk = sevkValue;
            break;
          }
        }
        setDetailData([...detailData]);        
       
        const updatedDataItem = data.find(item => item.key === row.CariKod);        
        let toplam_adet = 0;
        let toplam_hacim = 0;
        let toplam_kilo = 0;
        let sevkFactor = updatedItem;
        for (let i = 0; i < sevkFactor.length; i++) {
            let maxNum_ = sevkFactor[i].Miktar - sevkFactor[i].AracaYuklenenMiktar - sevkFactor[i].MusteriyeTeslimEdilenMiktar;                    
            toplam_hacim += sevkFactor[i].Hacim / maxNum_ * sevkFactor[i].sevk
            toplam_kilo += sevkFactor[i].Kg / maxNum_ * sevkFactor[i].sevk
            toplam_adet += Number(sevkFactor[i].sevk);            
        }
        updatedDataItem.volume = Number(toplam_hacim.toFixed(0));
        updatedDataItem.weight = Number(toplam_kilo.toFixed(0));
        updatedDataItem.count = Number(toplam_adet);
        updatedDataItem.checked = true;

        //------------------------------
        
        let eticketValueList =[];
        for(let i=0;i<groupEticket.length;i++){
          updatedDataItem.groupEticketValue[groupEticket[i]] = 0;          
        }
        for (let i = 0; i < sevkFactor.length; i++) {          
          updatedDataItem.groupEticketValue[sevkFactor[i].Grup] += sevkFactor[i].sevk;   
        }        
        //-----------------------------------

        // console.log('toplam_adet',toplam_adet);
        // console.log('r',updatedDataItem);
        setData([...data]);
        setTimeout(() => {
          virtuoso.current?.scrollToIndex({
          index,
          align: 'start',
          behavior: 'smooth'
        });
        }, 200);                
      };

      // console.log(data);

    const handleDetailSortClick = (parentId, columnName) => {    
        const table = DetailTable(parentId)
        const sortedDetails = [...table].sort((a, b) => a[columnName] > b[columnName] ? 1 : -1);
        //console.log(sortedDetails);        
        const newData = detailData.map((r) => ((r[0].CariKod === parentId)? sortedDetails: r));
        setDetailData(newData);
    };

    const detailFixedHeaderContent = (row) => {    
        // console.log(row);
        return (
          <TableRow key="header" sx={{backgroundColor:'#E91E63',width:'90%'}} >
            <TableCell width={'10%'} align="center"><MDTypography sx={{fontSize:'small'}}
                onMouseOver={handleMouseOver} onClick={() => handleDetailSortClick(row,'Tarih')}>Tarih</MDTypography></TableCell>
            <TableCell width={'10%'} align="center"><MDTypography sx={{fontSize:'small'}}
                onMouseOver={handleMouseOver} onClick={() => handleDetailSortClick(row,'EvrakNo')}>Evrak-No</MDTypography></TableCell>
            <TableCell width={'15%'} align="center"><MDTypography sx={{fontSize:'small'}}
                onMouseOver={handleMouseOver} onClick={() => handleDetailSortClick(row,'EvrakTip')}>Evrak-Tip</MDTypography></TableCell>
            <TableCell width={'8%'}align="center"><MDTypography sx={{fontSize:'small'}}
                onMouseOver={handleMouseOver} onClick={() => handleDetailSortClick(row,'Grup')}>Grup</MDTypography></TableCell>
            <TableCell width={'7%'}align="center"><MDTypography sx={{fontSize:'small'}}
                onMouseOver={handleMouseOver} onClick={() => handleDetailSortClick(row,'StokKodu')}>Kod</MDTypography></TableCell>
            <TableCell width={'20%'} align="center"><MDTypography sx={{fontSize:'small'}}
                onMouseOver={handleMouseOver} onClick={() => handleDetailSortClick(row,'StockAdi')}>Adi</MDTypography></TableCell>
            <TableCell width={'5%'} align="right"><MDTypography sx={{fontSize:'small'}}
                onMouseOver={handleMouseOver} onClick={() => handleDetailSortClick(row,'Miktar')}>Miktar</MDTypography></TableCell>
            <TableCell width={'5%'} align="right"><MDTypography sx={{fontSize:'small'}}
                onMouseOver={handleMouseOver} onClick={() => handleDetailSortClick(row,'AracaYuklenenMiktar')}>A.Y.M</MDTypography></TableCell>
            <TableCell width={'5%'} align="right"><MDTypography sx={{fontSize:'small'}}
                onMouseOver={handleMouseOver} onClick={() => handleDetailSortClick(row,'MusteriyeTeslimEdilenMiktar')}>T.E.M</MDTypography></TableCell>
            <TableCell width={'15%'} align="center"><MDTypography sx={{fontSize:'small',minWidth: '80px'}}>Sevk</MDTypography></TableCell>
          </TableRow>
        );
    }

    function rowContent(_index, row) {
        return (
            <TableRow key="header" sx={{width:'90%', backgroundColor: _index % 2 !== 0 ? '#f2f2f2' : 'white',fontSize:25}} >
                <TableCell width={'10%'} align="center"><MDTypography sx={{fontSize:'small'}}><SimpleDateTime dateFormat="DMY" dateSeparator="/" showTime="0"  timeSeparator=":">{row.Tarih}</SimpleDateTime></MDTypography></TableCell>
                <TableCell width={'10%'} align="center">{row.EvrakNo}</TableCell>
                <TableCell width={'15%'} align="center">{row.EvrakTip}</TableCell>
                <TableCell width={'8%'} align="center">{row.Grup}</TableCell>
                <TableCell width={'7%'} align="center">{row.StokKodu}</TableCell>
                <TableCell width={'20%'} align="center">{row.StokAdi}</TableCell>
                <TableCell width={'5%'} align="left">{row.Miktar}</TableCell>
                <TableCell width={'5%'} align="left">{row.AracaYuklenenMiktar}</TableCell>
                <TableCell width={'5%'} align="left">{row.MusteriyeTeslimEdilenMiktar}</TableCell>
                <TableCell width={'15%'} align="center">
                    <TextField
                        type="number" InputProps={{inputProps: { max: (row.Miktar - row.AracaYuklenenMiktar - row.MusteriyeTeslimEdilenMiktar), min: 0 }}} label="" 
                        defaultValue={row.sevk}
                        onChange={(event) => handleDetailCellChange(_index,row, Number(event.target.value))}
                    />
                </TableCell>
            </TableRow>
        );
    }

    const toggleExpandofRow = (row) => {
        // console.log(row);
        const newRow = { ...row, expanded: !row.expanded };
        // console.log(newRow);
        setData(prevData => {
          const index = prevData.findIndex(r => r.key === row.key);
          const updatedRows = [...prevData];
          updatedRows.splice(index, 1, newRow);
          return updatedRows;
        });
    };
    const DetailTable = (key) => {
        // console.log(detailData);
        let index = 0;
        for( let i=0; i<detailData.length;i++){            
            if(detailData[i][0].CariKod === key){
                index = i;
                break;
            }
        }       
        return detailData[index];       
    }
    const getGroupEticketValue = () =>{
      const eticketValueList = [];            
      for(let i=0;i<groupEticket.length;i++){        
        eticketValueList.push({name:groupEticket[i],value:0});
      }
      // console.log(eticketValueList);
      data.map( r => {        
        if(r.checked === true) {
         // console.log( r.groupEticketValue);
          for (let i = 0; i < groupEticket.length; i++) {
            eticketValueList[i].value += r.groupEticketValue[groupEticket[i]];
            //console.log(r.groupEticketValue[groupEticket[i]]);              
          }
        }
      });      
      return eticketValueList;
    }
    //console.log('getGroupEticketValue',getGroupEticketValue());
    //console.log('data',data);
    function Row(props) {
        const { row,index } = props;
        return (
          <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: index % 2 !== 0 ? '#FFF59D' : '#EEEEEE'}}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => toggleExpandofRow(row)}
                >
                  {row.expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell scope="row" style={{textAlign:'center'}}> 
                <input type="checkbox" id={row.key} name={row.key} style={{width: '20px', height: '20px'}} 
                    onChange={(event) => handleRowClick(event,row)}
                  defaultChecked={row.checked}></input>
              </TableCell>
              <TableCell scope="row"> {row.title}</TableCell>
              <TableCell align="center">{row.volume}</TableCell>
              <TableCell align="center">{row.weight}</TableCell>
              <TableCell align="center">{row.count}</TableCell>              
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={row.expanded} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1, width: '100%' }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Fatura Detayı
                    </Typography>                
                    <Paper style={{ height: 300, width: '100%' }}>                  
                      <TableVirtuoso
                        ref={virtuoso}
                        data={DetailTable(row.key) }
                        totalCount={DetailTable(row.key).length}
                        components={VirtuosoTableComponents}
                        fixedHeaderContent={ () => detailFixedHeaderContent(row.key)}
                        itemContent={rowContent}
                      />
                    </Paper>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      }
      
    Row.propTypes = {
        row: PropTypes.shape({
            key:PropTypes.string.isRequired,
            expanded:PropTypes.bool.isRequired,
            checked:PropTypes.bool.isRequired,
            title: PropTypes.string.isRequired,
            volume: PropTypes.number.isRequired,
            weight: PropTypes.number.isRequired,
            count: PropTypes.number.isRequired,          
        }).isRequired,
    };
    const handleSortClick = (columnName) => {
        setData([...data].sort((a, b) => (a[columnName] > b[columnName] ? 1 : -1)));
    };

    const handleMouseOver = (event) => {
        event.target.style.cursor = 'pointer';
    }
    const handleSelectedAll = (event)=>{
        setSelectedAll(!selectedAll);
        data.map((row)=>{
          if(!selectedAll){
            row.checked = true;        
          }else{
            row.checked = false;
          }
          return row;
        });
    }
    //--------------------------------------------------------
    const handleTrajectory = () =>{
      if(startDepoCoordinate.enlem ===0){
          Swal.fire({
              title: 'Dikkat!',
              text: 'Başlangıç Depo Seçilmelidir!',
              icon: 'error',
              confirmButtonText: 'OK'
            });
      }
      else{
          rowCo =[];
          rowCo.push({boylam:startDepoCoordinate.boylam,enlem:startDepoCoordinate.enlem,ad:startDepoCoordinate.ad});            
          if (data.length>0){
              for(let i=0;i<data.length;i++){
                  if(data[i].checked){
                    const sevkFactor  = detailData.find(item => item[0].CariKod === data[i].key);
                      rowCo.push({
                          boylam:sevkFactor[0].HedefBoylam,
                          enlem:sevkFactor[0].HedefEnlem,
                          ad:sevkFactor[0].CariUnvan});
                  }                    
              }
              if(rowCo.length>1){
                  // console.log('rowco',rowCo.length);
                  // console.log('rowco',rowCo);
                  toggle();
              }
              else{
                  Swal.fire({
                      title: 'Dikkat!',
                      text: 'En az bir maksat seçilmeli!',
                      icon: 'error',
                      confirmButtonText: 'OK'
                    });
              }
              
          }
      }
    }
    const toggle = () => {
      setShowModal(!showModal);        
    };
    const handleModalSubmit = (item) => {
      toggle();
      setActiveItem(item);
      // console.log('item of modal',item);
      if(item.traj_points.length>75){
          // console.log(item.all_distance);
          setAllDistance(item.all_distance);
          setShowMap(false);
          setMapResult(null);            
          setIswayPointshow(true);
      }
      else{
          setShowMap(false);
          rowlatLotPlaces = [];
          for(let i=0;i<item.traj_points.length;i++){
              rowlatLotPlaces.push(
                  {location:{ id: item.traj_points[i].name,
                              lat: item.traj_points[i].latlon[1],
                              lng: item.traj_points[i].latlon[0]}});
          }
          setShowMap(true);
      }     
  }
  function CalculateTime(sec){

    if(sec >= 0){
        var h = Math.floor(sec / 3600);
    var m = Math.floor(sec % 3600 / 60);
    var s = Math.floor(sec % 3600 % 60);
    }
    else{
        var h = Math.ceil(sec / 3600);
    var m = Math.ceil(sec % 3600 / 60);
    var s = Math.ceil(sec % 3600 % 60);
    }

    var hDisplay = h !== 0 ? h + (h == 1 ? " Saat, " : " Saat") + (m != 0 || s > 0 ? ", ":"") : "";
    var mDisplay = m !== 0 ? m + (m == 1 ? " Dakika, " : " Dakika")  + (s != 0 ? " ":""): "";
    var sDisplay = s !== 0 ? s + (s == 1 ? " Saniye" : " Saniye") : "";
    return hDisplay + mDisplay + sDisplay; 
  }

  const handleMapSubmit = (distance,duration) =>{
    // console.log('map ended',distance,duration);
    setAllDistance((distance/1000).toFixed(0)+' KM');
    setAllDuration(CalculateTime(duration));
    // console.log(CalculateTime(duration));

    setMapResult(rowlatLotPlaces);        
    setShowMap(false);
    setIswayPointshow(true);
  }

    //--------------------------------------------------------
    const handleRowClick = (event,row) => {        
        const updatedItem = data.find(item => item.key === row.key);
        updatedItem.checked = !updatedItem.checked;
        setData([...data]);
        let count =0;
        data.map(row =>{
          if(row.checked){
            count +=1;
          }
        });
        //console.log(count , myArray.length,count === myArray.length)
        if(count === data.length){
          setSelectedAll(true);
        }
        else{
          setSelectedAll(false);
        }

        if(event.target.checked){           
          setData(current =>
            current.map(obj => {
              if (obj.key === event.target.name) {
                  
                let toplam_hacim = 0;
                let toplam_kilo = 0;
                let toplam_adet = 0;
                let groupEticketValue=[];
                for (let k=0;k<groupEticket.length;k++){
                    groupEticketValue[groupEticket[k]] = 0;                
                }

                
                let sevkFactor = detailData.find(item => item[0].CariKod === obj.key);;
                
                for (let i = 0; i < sevkFactor.length; i++) {
                    let maxNum = sevkFactor[i].Miktar - sevkFactor[i].AracaYuklenenMiktar - sevkFactor[i].MusteriyeTeslimEdilenMiktar;
                    toplam_hacim += sevkFactor[i].Hacim / maxNum * sevkFactor[i].sevk
                    toplam_kilo += sevkFactor[i].Kg / maxNum * sevkFactor[i].sevk
                    toplam_adet += Number(sevkFactor[i].sevk);
                    groupEticketValue[sevkFactor[i].Grup] += sevkFactor[i].sevk;
                }
                toplam_hacim = toplam_hacim.toFixed(0);
                toplam_kilo = toplam_kilo.toFixed(0);                                     
                return {...obj, 'volume':toplam_hacim, 'weight':toplam_kilo, 'count':toplam_adet,groupEticketValue:groupEticketValue ,checked:true};
              }              
              return obj;
            }),
          );
        }
        else{
          setData(current =>
            current.map(obj => {          
              if (obj.key === event.target.name) {            
                let groupEticketValue=[];
                for (let k=0;k<groupEticket.length;k++){
                    groupEticketValue[groupEticket[k]] = 0;                
                }                          
                return {...obj, 'volume':0, 'weight':0, 'count':0,checked:false,groupEticketValue: groupEticketValue};
              }              
              return obj;
            }),
          );
          }
    };

    return (
      <>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {getGroupEticketValue().map((item, index) => (
          <Grid xs={2} sm={4} md={4} key={index}>            
            <Stack direction="row" alignItems="center" gap={1}>
              <Icon fontSize='medium'>animation</Icon>
              <MDTypography variant="body1" sx={{fontSize:14,fontWeight:600}} color="dark" >{item.name}:</MDTypography>
              <MDTypography variant="body1" sx={{fontWeight:600}}> {item.value}</MDTypography>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid xs={2} sm={4} md={4} >
          <Stack direction="row" alignItems="center" gap={1}>
            <Icon fontSize='medium'>healing</Icon>
            <MDTypography variant="body1" sx={{fontSize:14,fontWeight:600}} color="dark" >Toplam Hacim:</MDTypography>
            <MDTypography variant="body1" sx={{fontWeight:600}}> {data.reduce((prevValue, currentValue) => Number(prevValue) + Number(currentValue.volume),0)}</MDTypography>
          </Stack>
        </Grid>
        <Grid xs={2} sm={4} md={4} >
          <Stack direction="row" alignItems="center" gap={1}>
            <Icon fontSize='medium'>scale</Icon>
            <MDTypography variant="body1" sx={{fontSize:14,fontWeight:600}} color="dark">Toplam Kg:</MDTypography>
            <MDTypography variant="body1" sx={{fontWeight:600}}> {data.reduce((prevValue, currentValue) => Number(prevValue) + Number(currentValue.weight),0)}</MDTypography>
          </Stack>
        </Grid>
        <Grid xs={2} sm={4} md={4} >
          <Stack direction="row" alignItems="center" gap={1}>
            <Icon fontSize='medium' >fence</Icon>
            <MDTypography variant="body1" sx={{fontSize:14,fontWeight:600}} color="dark">Toplam Adet:</MDTypography>
            <MDTypography variant="body1" sx={{fontWeight:600}}> {data.reduce((prevValue, currentValue) => Number(prevValue) + Number(currentValue.count),0)}</MDTypography>
          </Stack>   
          <Stack direction="row" alignItems="center" gap={1}>                    
            <Fab variant="extended" onClick={handleTrajectory} sx={{height:40,width:130,fontSize:14,fontWeight:600}} color="primary">  
                <Icon fontSize='medium' >location_searching</Icon>                       
                {' '}Yörünge
            </Fab>
          </Stack>     
        </Grid>  
              
      </Grid>      
        
      {
          !iswayPointshow?null:(
              <MapReport allDistance={allDistance} allDuration={allDuration} activeItem={activeItem} mapResult = {mapResult}></MapReport>
          )
      }
        <TableContainer component={Paper}>
            <table style={{width: "97%", tableLayout: "fixed"}}>
                <thead>
                    <tr style={{backgroundColor:'#3F51B5',height:'50px'}}>
                        <th style={{fontSize:20,fontWeight:'bold',width:'5%'}}></th>
                        <th style={{fontSize:20,fontWeight:'bold',width:'8%',textAlign:'center'}}>
                            <input type="checkbox" style={{width: '20px', height: '20px'}} 
                                checked={selectedAll} onChange={handleSelectedAll} 
                            />
                        </th>           
                        <th style={{fontSize:20,fontWeight:'bold',width:'46%',textAlign:'center'}} onMouseOver={handleMouseOver} onClick={() => handleSortClick('title')}>Başlık</th>
                        <th style={{fontSize:20,fontWeight:'bold',width:'15%',textAlign:'center'}} onMouseOver={handleMouseOver} onClick={() => handleSortClick('volume')}>Hacim (Desi)</th>
                        <th style={{fontSize:20,fontWeight:'bold',width:'15%',textAlign:'center'}} onMouseOver={handleMouseOver} onClick={() => handleSortClick('weight')}>Ağırlık (KG)</th>
                        <th style={{fontSize:20,fontWeight:'bold',width:'10%',textAlign:'center'}} onMouseOver={handleMouseOver} onClick={() => handleSortClick('count')}>Adet</th>            
                        
                    </tr>
                </thead>
                <tbody>
                    {data.map((row,index) => (
                        <Row key={row.key} row={row} index={index} />
                    ))}
                </tbody>
            </table>
        </TableContainer>
        {showModal ? (
      <Modal
        activeItem={activeItem}
        rowCoordinates ={rowCo}
        toggle={toggle}
        onSave={handleModalSubmit}
      />
    ) : null}
    {showMap ? (
    <Map        
        places ={rowlatLotPlaces}        
        onSave={handleMapSubmit}
      />
      ) : null}
      </>
    );
}

export default GroupDataGrid;