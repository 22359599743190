import React, { useState,useEffect } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,  
  DirectionsRenderer,
} from '@react-google-maps/api';
import { duration } from "@mui/material";
var origin,destination;
function MapDirectionsRenderer({places,onSave}) {    
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);    
  const [directionsResponse2, setDirectionsResponse2] = useState(null);    
  const [directionsResponse3, setDirectionsResponse3] = useState(null);    

 
  useEffect(() => {
    // console.log('start Map');
    let timer = setTimeout(() => {      
      calculateRoute();      
  }, 1000);  

  return () => clearTimeout(timer)
  }, []);

  
  if (!isLoaded) {
    return 'LOading ... ';
  }

  async function calculateRoute() {
    // if (originRef.current.value === '' || destiantionRef.current.value === '') {
    //   return
    // }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    // console.log('Start Clac',places.length);
    let all_distance = 0;
    let all_duration = 0
    if(places.length<26){
      const routesCopy = places.map(route => {
        return {
          location: { lat: route.location.lat, lng: route.location.lng },
          stopover: true
        };
      });
      origin=routesCopy.length===1 ? new window.google.maps.LatLng(routesCopy[0].location.lat,routesCopy[0].location.lng): routesCopy.shift().location;
      // console.log('or',origin);
      destination=routesCopy.length === 1? new window.google.maps.LatLng(routesCopy[0].location.lat,routesCopy[0].location.lng): routesCopy.pop().location;
      // console.log('destination',destination);
      const waypoints = routesCopy;
    //waypoints.length >= 1?    
      const results = await directionsService.route(
            {
              origin: origin,
              destination: destination,
              travelMode: window.google.maps.TravelMode.DRIVING,
              waypoints: waypoints
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                //changing the state of directions to the result of direction service
                setDirectionsResponse(result);
                // console.log(result.routes[0].legs[0].distance.text);
                let item = result.routes[0].legs;
                
                for(let i=0;i<item.length;i++)
                {
                  //console.log('item',item[i].duration.value);
                  all_duration+= item[i].duration.value;
                  all_distance+=item[i].distance.value;
                }
                onSave(all_distance,all_duration);
                // console.log(all_distance);
              } else {
                console.error(`error fetching directions ${result}`);
              }
            }
          );
    }else if(places.length<51){
      const places1 = places.slice(0, 26);
      const places2 = places.slice(25, places.length);
      // console.log('places',places);
      // console.log('places1',places1);
      // console.log('places2',places2);
      const routesCopy1 = places1.map(route => {
        return {
          location: { lat: route.location.lat, lng: route.location.lng },
          stopover: true
        };
      });
      const routesCopy2 = places2.map(route => {
        return {
          location: { lat: route.location.lat, lng: route.location.lng },
          stopover: true
        };
      });
      
      let origin1 =routesCopy1.length===1 ? new window.google.maps.LatLng(routesCopy1[0].location.lat,routesCopy1[0].location.lng): routesCopy1.shift().location;
      origin = origin1;
      // console.log('or',origin1);
      let destination1=routesCopy1.length === 1? new window.google.maps.LatLng(routesCopy1[0].location.lat,routesCopy1[0].location.lng): routesCopy1.pop().location;
      // console.log('destination',destination1);
      const waypoints1 = routesCopy1;
      const results1 = await directionsService.route(
            {
              origin: origin1,
              destination: destination1,
              travelMode: window.google.maps.TravelMode.DRIVING,
              waypoints: waypoints1
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                //changing the state of directions to the result of direction service
                setDirectionsResponse(result);
                // console.log(result.routes[0].legs[0].distance.text);
                let item = result.routes[0].legs;                
                for(let i=0;i<item.length;i++)
                {
                  all_duration+= item[i].duration.value;
                  all_distance+=item[i].distance.value;
                }
                
                // console.log(all_distance);
              } else {
                console.error(`error fetching directions ${result}`);
              }
            }
          );
      let origin2 =routesCopy2.length===1 ? new window.google.maps.LatLng(routesCopy2[0].location.lat,routesCopy2[0].location.lng): routesCopy2.shift().location;
      // console.log('or',origin2);
      let destination2=routesCopy2.length === 1? new window.google.maps.LatLng(routesCopy2[0].location.lat,routesCopy2[0].location.lng): routesCopy2.pop().location;
      // console.log('destination',destination2);
      const waypoints2 = routesCopy2;
    //waypoints.length >= 1?    
      const results = await directionsService.route(
            {
              origin: origin2,
              destination: destination2,
              travelMode: window.google.maps.TravelMode.DRIVING,
              waypoints: waypoints2
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                //changing the state of directions to the result of direction service
                setDirectionsResponse2(result);
                // console.log(result.routes[0].legs[0].distance.text);
                let item = result.routes[0].legs;
                
                for(let i=0;i<item.length;i++)
                {
                  all_duration+= item[i].duration.value;
                  all_distance+=item[i].distance.value;
                }                
                // console.log(all_distance);
              } else {
                console.error(`error fetching directions ${result}`);
              }
            }
          );
    }else if(places.length<70){
      const places1 = places.slice(0, 26);
      const places2 = places.slice(25, 49);
      const places3 = places.slice(48, places.length);
      // console.log('places',places);
      // console.log('places1',places1);
      // console.log('places2',places2);
      // console.log('places2',places3);
      
      const routesCopy1 = places1.map(route => {
        return {
          location: { lat: route.location.lat, lng: route.location.lng },
          stopover: true
        };
      });
      const routesCopy2 = places2.map(route => {
        return {
          location: { lat: route.location.lat, lng: route.location.lng },
          stopover: true
        };
      });
      const routesCopy3 = places3.map(route => {
        return {
          location: { lat: route.location.lat, lng: route.location.lng },
          stopover: true
        };
      });
      
      let origin1 =routesCopy1.length===1 ? new window.google.maps.LatLng(routesCopy1[0].location.lat,routesCopy1[0].location.lng): routesCopy1.shift().location;
      origin = origin1;
      // console.log('or',origin1);
      let destination1=routesCopy1.length === 1? new window.google.maps.LatLng(routesCopy1[0].location.lat,routesCopy1[0].location.lng): routesCopy1.pop().location;
      // console.log('destination',destination1);
      const waypoints1 = routesCopy1;
      const results1 = await directionsService.route(
            {
              origin: origin1,
              destination: destination1,
              travelMode: window.google.maps.TravelMode.DRIVING,
              waypoints: waypoints1
            },
            (result1, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                //changing the state of directions to the result of direction service
                setDirectionsResponse(result1);
                // console.log(result.routes[0].legs[0].distance.text);
                let item = result1.routes[0].legs;                
                for(let i=0;i<item.length;i++)
                {
                  all_duration+= item[i].duration.value;
                  all_distance+=item[i].distance.value;
                }
                
                // console.log(all_distance);
              } else {
                console.error(`error fetching directions ${result1}`);
              }
            }
          );
      // console.log('step0', all_distance);
      let origin2 =routesCopy2.length===1 ? new window.google.maps.LatLng(routesCopy2[0].location.lat,routesCopy2[0].location.lng): routesCopy2.shift().location;
      // console.log('or',origin2);
      let destination2=routesCopy2.length === 1? new window.google.maps.LatLng(routesCopy2[0].location.lat,routesCopy2[0].location.lng): routesCopy2.pop().location;
      // console.log('destination',destination2);
      const waypoints2 = routesCopy2;
    //waypoints.length >= 1?    
      const results2 = await directionsService.route(
            {
              origin: origin2,
              destination: destination2,
              travelMode: window.google.maps.TravelMode.DRIVING,
              waypoints: waypoints2
            },
            (result2, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                //changing the state of directions to the result of direction service
                setDirectionsResponse2(result2);
                // console.log(result.routes[0].legs[0].distance.text);
                let item = result2.routes[0].legs;
                
                for(let i=0;i<item.length;i++)
                {
                  all_duration+= item[i].duration.value;
                  all_distance+=item[i].distance.value;
                }                
                // console.log(all_distance);
              } else {
                console.error(`error fetching directions ${result2}`);
              }
            }
          );
          // console.log('step1', all_distance);

          let origin3 =routesCopy3.length===1 ? new window.google.maps.LatLng(routesCopy3[0].location.lat,routesCopy3[0].location.lng): routesCopy3.shift().location;
          // console.log('or',origin3);
          let destination3=routesCopy3.length === 1? new window.google.maps.LatLng(routesCopy3[0].location.lat,routesCopy3[0].location.lng): routesCopy3.pop().location;
          // console.log('destination',destination3);
          const waypoints3 = routesCopy3;
        //waypoints.length >= 1?    
          const results3 = await directionsService.route(
                {
                  origin: origin3,
                  destination: destination3,
                  travelMode: window.google.maps.TravelMode.DRIVING,
                  waypoints: waypoints3
                },
                (result3, status) => {
                  if (status === window.google.maps.DirectionsStatus.OK) {
                    //changing the state of directions to the result of direction service
                    setDirectionsResponse3(result3);
                    // console.log(result.routes[0].legs[0].distance.text);
                    let item = result3.routes[0].legs;
                    
                    for(let i=0;i<item.length;i++)
                    {
                      all_duration+= item[i].duration.value;
                      all_distance+=item[i].distance.value;
                    }                
                    // console.log(all_distance);
                  } else {
                    console.error(`error fetching directions ${result3}`);
                  }
                }
              );
              // console.log('step2', all_distance);

    }

    
    // console.log('all ',all_distance);
    onSave(all_distance,all_duration);
      // console.log('results',results.routes[0].legs.length);    
  }

   return (    
        <GoogleMap
          center={origin}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={map => setMap(map)}
        >
          {/* <Marker position={origin}  label='Başlangıç' /> */}
           {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}  
          {directionsResponse2 && (
            <DirectionsRenderer directions={directionsResponse2} />
          )}
          {directionsResponse3 && (
            <DirectionsRenderer directions={directionsResponse3} />
          )} 
        </GoogleMap>      
  );
  }
  export default MapDirectionsRenderer;