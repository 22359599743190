// @mui material components
import React, { useState, useEffect } from 'react';
import MDBox from "components/MDBox";
import DashboardLayout from "pages/common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/common/Navbars/DashboardNavbar";
import Searchers from './searcher'
import LocMap from './locMap';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useMaterialUIController } from "context";
import {API_URL,Accordion,AccordionSummary,
  AccordionDetails,Item} from 'pages/common/constants';

const ItemMap = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Locations() {
  const [center,setCenter] = useState({lat: 40.193298, lng: 29.074202 });
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  function success(pos) {
    const crd = pos.coords;      
    // console.log('Your current position is:');
    // console.log(`Latitude : ${crd.latitude}`);
    // console.log(`Longitude: ${crd.longitude}`);
    // console.log(`More or less ${crd.accuracy} meters.`);
    setCenter({lat: crd.latitude, lng: crd.longitude });      
  }  
  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success, error, options);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3}>        
        <Stack spacing={2}>
          <ItemMap sx={{ width: '100%',height:'380px'}}><LocMap center = {center}></LocMap></ItemMap>
          <Item dark={darkMode ? 1 : 0}><Searchers center = {center}></Searchers></Item>
        </Stack>
      </MDBox>
    </DashboardLayout>
  );
}
export default Locations;
