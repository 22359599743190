// @mui material components
import React, { useState, useEffect } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useToken from "pages/components/useToken";
import MDButton from "components/MDButton";
import Stack from '@mui/material/Stack';
import Swal from 'sweetalert2';
import MapModal from './mapModal'; 

import {API_URL,Item} from 'pages/common/constants';
const url_save = API_URL + "/api/query";

function RouteList() {
    const [rotalar,setRotalar] = useState([]);
    const { token, setToken } = useToken(); 
    const [rota, setRota] = useState('');
    const [places, setPlaces] = useState('');
    const [showModal,setShowModal] = useState(false);
    
    const toggle = () => {
        setShowModal(!showModal);        
    };

    useEffect(() => {    
        let timer = setTimeout(() => {
            var data = {
                username: token.username,                        
            }      
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',dataType:'json' },
                body: JSON.stringify({ "tip": 41, "data": data })
            };                      
            fetch(url_save, requestOptions)
            .then(response => response.json())
                .then(data => { 
                    //console.log(data.data);
                    setRotalar(data.data);
                });

        }, 1000);    
        return () => clearTimeout(timer);        
      }, []);

    const isLoading = rotalar.length === 0;   
    const handleChangeRotalar = (event) =>{
        setRota(event.target.value);
    }
    const handleGoruntule = ()  =>{
        
        if(rota === ''){
            Swal.fire({
                title: 'Dikkat!',
                text: 'Bir rota seçilmeli!',
                icon: 'error',
                confirmButtonText: 'OK'
              });  
        }else{
            let index=-1;
            for(let i=0;i<rotalar.length;i++){                
                if(rotalar[i].mapname === rota){
                    index = i;
                    break;
                }
            }
            // console.log(index);
            // console.log(rotalar[index].id);
            setPlaces(rotalar[index].places);
            setShowModal(true);
        }
    }

    return (   
        <MDBox pt={1} pb={3}>
            <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography variant="h6" color="white">
                            Lütfen Listeden Bir Rota Seçin!
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={3} py={3} px={3}>
                        <Stack direction="row" spacing={2}>
                            <FormControl id='frmRegion' fullWidth sx={{}}>
                                <InputLabel id="regions-select-label">
                                    <MDTypography variant="body2" sx={{fontSize:14,fontWeight:600}} color="dark">Rotalar</MDTypography>
                                </InputLabel>
                                <Select style={{ marginTop: 1, marginLeft: 1, width:'100%',height:'50px' }}
                                    labelId="regions-select-label"
                                    id="regions-select"
                                    value={rota}
                                    label="Bölge"
                                    onChange={handleChangeRotalar}
                                >
                                    {isLoading ? <MDTypography sx={{fontSize:14,fontWeight:600}}>Loading...</MDTypography>
                                :
                                    rotalar.map((rota,index)=>(
                                    <MenuItem key= {index} value={rota.mapname}><MDTypography sx={{fontSize:14,fontWeight:600}}>{rota.mapname}</MDTypography></MenuItem>
                                    ))
                                    }                    
                                </Select>
                            </FormControl>
                            <MDButton style={{ width:'150px',height:'50px' }} variant="contained" color="success" onClick={handleGoruntule}>Görüntüle</MDButton>
                        </Stack>
                    </MDBox>
            </Card>
          </Grid>
        </Grid>
        {showModal ? (
            <MapModal                
                places={places}                
                toggle={toggle}                
            />
            ) : null}
    </MDBox>    
  );
}
export default RouteList;
