
function Logout() {
    
  
    localStorage.clear();
    // console.log('Cleared');     
    window.location.replace('/');
      return(
        <>
            <h1>Logouting ....</h1>
        </>

      )
    }  

export default Logout;
