import { useState,useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "./BasicLayout";
import Swal from 'sweetalert2';
import {API_URL} from 'pages/common/constants';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import UserIcon from '@mui/icons-material/AccountCircleTwoTone';
import PasswordIcon from '@mui/icons-material/VpnKeyTwoTone';

// Images
import bgImage from "./bg-sign-in-basic.jpeg";

function Login({ setToken }) {
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    localStorage.clear();
  }, []); 

    const Auth = async (credentials) => {
        try {
          let url = API_URL +"/api/login/";
          //console.log(url);
          const resp = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(credentials)
          });          
          if(resp.status === 200 ){            
            var token = { token: 'authenticated',
                        username:username
                        //,
                        //password:password
                    };
            setToken(token);
            // console.log('Start Nav');
            let timerInterval;
                    Swal.fire({
                    title: 'Başarılı',
                    html: 'Giriş başarılı yönlendiriliyorsunuz.',
                    timer: 1000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                    }).then((result) => {
                        window.location = '/'
                    })

            //navigate("/dashboard");
          }else{            
            Swal.fire({
              title: 'Dikkat!',
              text: 'Kullanıcı adı ya da şifre yanlış!',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          }          
          return await resp.json();
        } catch (e) {
          // console.log(e);
          Swal.fire({
            title: 'Dikkat!',
            text: String(e),
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();        
        // console.log(username,password);
        if (username === "" || password === "") {           
           Swal.fire({
            title: 'Dikkat!',
            text: 'Kullanıcı adı ya da şifre boş bırakılmamalı!',
            icon: 'error',
            confirmButtonText: 'OK'
          });        
        } else {
          const data = await Auth({ username, password });                    
        }
      };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>          
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <Stack direction="row" >
                <UserIcon fontSize="large" sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField fullWidth  label="Kullanıcı Adı" onChange={(e) => setUsername(e.target.value)}/>                
              </Stack>
            </MDBox>
            <MDBox mb={2}>
              <Stack direction="row" >
              <PasswordIcon fontSize="large" sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField fullWidth label="Şifre" type="password" onChange={(e) => setPassword(e.target.value)}/>
              </Stack>
            </MDBox>            
            <MDBox mt={4} mb={1}>
              <MDButton onClick={handleSubmit} variant="gradient" color="info" fullWidth>
                  Giriş Yap
              </MDButton>                     
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
