import React,{useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {
    useJsApiLoader,
    GoogleMap,
    Marker,  
    DirectionsRenderer,
  } from '@react-google-maps/api';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

var origin;
var markers= [];
export default function FullScreenDialog({places,toggle}) { 
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
      });
      
    const [map, setMap] = React.useState(/** @type google.maps.Map */ (null));
    useEffect(() => {    
        places = JSON.parse(places);
        //console.log('places',places.length);
        let timer = setTimeout(() => {
            const bounds = new window.google.maps.LatLngBounds();
            origin = new window.google.maps.LatLng(places[0].location.lat,places[0].location.lng);
            markers=[];
            for(let i=0;i<places.length;i++){
              markers.push(places[i].location.id);
              bounds.extend({ lat: places[i].location.lat, lng: places[i].location.lng });
            }        
            map.fitBounds(bounds)
            calculateRoute();
        }, 3000);    
        return () => clearTimeout(timer);        
      }, [map]);
      //------------------------------
      const calculateRoute = () => {
        const directionService = new window.google.maps.DirectionsService();    
        if(places.length<26){
          const routesCopy = places.map(route => {
            return {
              location: { lat: route.location.lat, lng: route.location.lng },
              stopover: true
            };
          });
          var directionsDisplay = new window.google.maps.DirectionsRenderer({
            map: map,
            suppressMarkers: true
          });
          //console.log('*',routesCopy.length);
          let origin=routesCopy.length===1 ? new window.google.maps.LatLng(routesCopy[0].location.lat,routesCopy[0].location.lng): routesCopy.shift().location;
          //console.log('or',origin);
          let destination=routesCopy.length === 1? new window.google.maps.LatLng(routesCopy[0].location.lat,routesCopy[0].location.lng): routesCopy.pop().location;
          //console.log('destination',destination);
          //const waypoints = routesCopy;
          directionService.route({
            origin: origin,
            destination: destination,
            waypoints: routesCopy, 
            travelMode: window.google.maps.TravelMode.DRIVING
          },function(response, status) {           
              if (status === window.google.maps.DirectionsStatus.OK) {
                  directionsDisplay.setMap(map);
                  directionsDisplay.setDirections(response);
                  var my_route = response.routes[0];
                  var infowindow = new window.google.maps.InfoWindow();
                  var marker, i;
                  for (i = 1; i < my_route.legs.length; i++) {
                      marker = new window.google.maps.Marker({
                          position: my_route.legs[i].start_location,
                          label:{text:""+(i+1),color:'white'},
                          map: map
                      });
                      window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                          return function() {
                              infowindow.setContent(markers[i]);
                              infowindow.open(map, marker);
                          }
                      })(marker, i));
                  }
                  marker = new window.google.maps.Marker({
                      position: my_route.legs[i-1].end_location,
                      label:{text:""+(i+1),color:'white'},
                      map: map
                  });
                  window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                      return function() {
                        //console.log('++++',markers);
                          infowindow.setContent(markers[i]);
                          infowindow.open(map, marker);
                      }
                  })(marker, i));
              }
            }
          );
        }else if(places.length<51){
          var directionsDisplay1 = new window.google.maps.DirectionsRenderer({
            map: map,
            suppressMarkers: true
          });
          var directionsDisplay2 = new window.google.maps.DirectionsRenderer({
            map: map,
            suppressMarkers: true
          });
          const places1 = places.slice(0, 26);
          const places2 = places.slice(25, places.length);
          const routesCopy1 = places1.map(route => {
            return {
              location: { lat: route.location.lat, lng: route.location.lng },
              stopover: true
            };
          });
          const routesCopy2 = places2.map(route => {
            return {
              location: { lat: route.location.lat, lng: route.location.lng },
              stopover: true
            };
          });
          let origin1=routesCopy1.length===1 ? new window.google.maps.LatLng(routesCopy1[0].location.lat,routesCopy1[0].location.lng): routesCopy1.shift().location;
          let destination1=routesCopy1.length === 1? new window.google.maps.LatLng(routesCopy1[0].location.lat,routesCopy1[0].location.lng): routesCopy1.pop().location;
    
          let origin2=routesCopy2.length===1 ? new window.google.maps.LatLng(routesCopy2[0].location.lat,routesCopy2[0].location.lng): routesCopy2.shift().location;
          let destination2=routesCopy2.length === 1? new window.google.maps.LatLng(routesCopy2[0].location.lat,routesCopy2[0].location.lng): routesCopy2.pop().location;
    
          const results1 = directionService.route({
            origin: origin1,
            destination: destination1,
            waypoints: routesCopy1, 
            travelMode: window.google.maps.TravelMode.DRIVING
          },function(response, status) {           
              if (status === window.google.maps.DirectionsStatus.OK) {
                directionsDisplay1.setMap(map);
                directionsDisplay1.setDirections(response);
                var my_route = response.routes[0];
                var infowindow = new window.google.maps.InfoWindow();
                var marker, i;
                for (i = 1; i < my_route.legs.length; i++) {
                    marker = new window.google.maps.Marker({
                        position: my_route.legs[i].start_location,
                        label:{text:""+(i+1),color:'white'},
                        map: map
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                        return function() {
                            infowindow.setContent(markers[i]);
                            infowindow.open(map, marker);
                        }
                    })(marker, i));
                }
                marker = new window.google.maps.Marker({
                    position: my_route.legs[i-1].end_location,
                    label:{text:""+(i+1),color:'white'},
                    map: map
                });
                window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                    return function() {
                     // console.log('++++',markers);
                        infowindow.setContent(markers[i]);
                        infowindow.open(map, marker);
                    }
                })(marker, i));
              }
            }
          );
          const results2 = directionService.route({
            origin: origin2,
            destination: destination2,
            waypoints: routesCopy2, 
            travelMode: window.google.maps.TravelMode.DRIVING
          },function(response, status) {           
              if (status === window.google.maps.DirectionsStatus.OK) {
                directionsDisplay2.setMap(map);
                directionsDisplay2.setDirections(response);
                var my_route = response.routes[0];
                var infowindow = new window.google.maps.InfoWindow();
                var marker, i;
                for (i = 1; i < my_route.legs.length; i++) {
                    marker = new window.google.maps.Marker({
                        position: my_route.legs[i].start_location,
                        label:{text:""+(25+i+1),color:'white'},
                        map: map
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                        return function() {
                            infowindow.setContent(markers[25+i]);
                            infowindow.open(map, marker);
                        }
                    })(marker, i));
                }
                marker = new window.google.maps.Marker({
                    position: my_route.legs[i-1].end_location,
                    label:{text:""+(25+i+1),color:'white'},
                    map: map
                });
                window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                    return function() {
                      //console.log('++++',markers);
                        infowindow.setContent(markers[25+i]);
                        infowindow.open(map, marker);
                    }
                })(marker, i));
              }
            }
          );
        }else if(places.length<70){
          var directionsDisplay1 = new window.google.maps.DirectionsRenderer({
            map: map,
            suppressMarkers: true
          });
          var directionsDisplay2 = new window.google.maps.DirectionsRenderer({
            map: map,
            suppressMarkers: true
          });
          var directionsDisplay3 = new window.google.maps.DirectionsRenderer({
            map: map,
            suppressMarkers: true
          });
          const places1 = places.slice(0, 26);
          const places2 = places.slice(25, 49);
          const places3 = places.slice(48, places.length);
          const routesCopy1 = places1.map(route => {
            return {
              location: { lat: route.location.lat, lng: route.location.lng },
              stopover: true
            };
          });
          const routesCopy2 = places2.map(route => {
            return {
              location: { lat: route.location.lat, lng: route.location.lng },
              stopover: true
            };
          });
    
          const routesCopy3 = places3.map(route => {
            return {
              location: { lat: route.location.lat, lng: route.location.lng },
              stopover: true
            };
          });
    
          let origin1=routesCopy1.length===1 ? new window.google.maps.LatLng(routesCopy1[0].location.lat,routesCopy1[0].location.lng): routesCopy1.shift().location;
          let destination1=routesCopy1.length === 1? new window.google.maps.LatLng(routesCopy1[0].location.lat,routesCopy1[0].location.lng): routesCopy1.pop().location;
    
          let origin2=routesCopy2.length===1 ? new window.google.maps.LatLng(routesCopy2[0].location.lat,routesCopy2[0].location.lng): routesCopy2.shift().location;
          let destination2=routesCopy2.length === 1? new window.google.maps.LatLng(routesCopy2[0].location.lat,routesCopy2[0].location.lng): routesCopy2.pop().location;
    
          let origin3 = routesCopy3.length===1 ? new window.google.maps.LatLng(routesCopy3[0].location.lat,routesCopy3[0].location.lng): routesCopy3.shift().location;
          let destination3 = routesCopy3.length === 1? new window.google.maps.LatLng(routesCopy3[0].location.lat,routesCopy3[0].location.lng): routesCopy3.pop().location;
          // console.log('routesCopy3',routesCopy3);
          // console.log('destination3',destination3);
          
          //--------------
          const results1 = directionService.route({
            origin: origin1,
            destination: destination1,
            waypoints: routesCopy1, 
            travelMode: window.google.maps.TravelMode.DRIVING
          },function(response, status) {           
              if (status === window.google.maps.DirectionsStatus.OK) {
                directionsDisplay1.setMap(map);
                directionsDisplay1.setDirections(response);
                var my_route = response.routes[0];
                var infowindow = new window.google.maps.InfoWindow();
                var marker, i;
                for (i = 1; i < my_route.legs.length; i++) {
                    marker = new window.google.maps.Marker({
                        position: my_route.legs[i].start_location,
                        label:{text:""+(i+1),color:'white'},
                        map: map
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                        return function() {
                            infowindow.setContent(markers[i]);
                            infowindow.open(map, marker);
                        }
                    })(marker, i));
                }
                marker = new window.google.maps.Marker({
                    position: my_route.legs[i-1].end_location,
                    label:{text:""+(i+1),color:'white'},
                    map: map
                });
                window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                    return function() {
                      //console.log('++++',markers);
                        infowindow.setContent(markers[i]);
                        infowindow.open(map, marker);
                    }
                })(marker, i));
              }
            }
          );
    
          const results2 = directionService.route({
            origin: origin2,
            destination: destination2,
            waypoints: routesCopy2, 
            travelMode: window.google.maps.TravelMode.DRIVING
          },function(response, status) {           
              if (status === window.google.maps.DirectionsStatus.OK) {
                directionsDisplay2.setMap(map);
                directionsDisplay2.setDirections(response);
                var my_route = response.routes[0];
                var infowindow = new window.google.maps.InfoWindow();
                var marker, i;
                for (i = 1; i < my_route.legs.length; i++) {
                    marker = new window.google.maps.Marker({
                        position: my_route.legs[i].start_location,
                        label:{text:""+(25+i+1),color:'white'},
                        map: map
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                        return function() {
                            infowindow.setContent(markers[25+i]);
                            infowindow.open(map, marker);
                        }
                    })(marker, i));
                }
                marker = new window.google.maps.Marker({
                    position: my_route.legs[i-1].end_location,
                    label:{text:""+(25+i+1),color:'white'},
                    map: map
                });
                window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                    return function() {
                      //console.log('++++',markers);
                        infowindow.setContent(markers[25+i]);
                        infowindow.open(map, marker);
                    }
                })(marker, i));
              }
            }
          );
          //-------------------------
          // console.log()
          const results3 = directionService.route({
            origin: origin3,
            destination: destination3,
            waypoints: routesCopy3, 
            travelMode: window.google.maps.TravelMode.DRIVING
          },function(response, status) {           
              if (status === window.google.maps.DirectionsStatus.OK) {
                directionsDisplay3.setMap(map);
                directionsDisplay3.setDirections(response);
                var my_route = response.routes[0];
                var infowindow = new window.google.maps.InfoWindow();
                var marker, i;
                for (i = 1; i < my_route.legs.length; i++) {
                  // console.log('label',48+i+1);
                    marker = new window.google.maps.Marker({
                        position: my_route.legs[i].start_location,
                        label:{text:""+(48+i+1),color:'white'},
                        map: map
                    });
                    window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                        return function() {
                            infowindow.setContent(markers[48+i]);
                            infowindow.open(map, marker);
                        }
                    })(marker, i));
                }
                // console.log('label',48+i+1);
                marker = new window.google.maps.Marker({
                    position: my_route.legs[i-1].end_location,
                    label:{text:""+(48+i+1),color:'white'},
                    map: map
                });
                window.google.maps.event.addListener(marker, 'click', (function(marker, i) {
                    return function() {
                      //console.log('++++',markers);
                        infowindow.setContent(markers[48+i]);
                        infowindow.open(map, marker);
                    }
                })(marker, i));
              }
            }
          );
          //-----------------
    
        }
    }
    //------------------------------
      
    if (!isLoaded) {
        return 'LOading ... ';
    }
    //console.log(places);
  return (
    <div>      
      <Dialog
        fullScreen
        open={true}
        onClose={toggle}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative',backgroundColor:'#a5b5ff' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggle}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>            
          </Toolbar>
        </AppBar>
        <GoogleMap
          center={origin}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={map => setMap(map)}
        >
          <Marker position={origin}  label={{text:'Başlangıç',color:'green'}} />
           
        </GoogleMap>
      </Dialog>
    </div>
  );
}