// Material Dashboard 2 React layouts
import Sevkiyat from "pages/shipment";
import Raporlar from "pages/reports";
import Locations from "pages/car_locations";
import Rotalar from "pages/rotalar";
import Logout from "pages/logout";
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';


// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Sevkiyat",
    key: "sevkiyat",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/sevkiyat",
    component: <Sevkiyat />,
  },
  {
    type: "collapse",
    name: "Raporlar",
    key: "Reports",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/raporlar",
    component: <Raporlar />,
  },  
  {
    type: "collapse",
    name: "Lokasiyonlar",
    key: "Locations",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/locations",
    component: <Locations />,
  },  
  {
    type: "collapse",
    name: "Rotalar",
    key: "Rotalar",
    icon: <Icon fontSize="small">route</Icon>,
    route: "/rotalar",
    component: <Rotalar />,
  },  
  {
    type: "collapse",
    name: "Çıkış Yap",
    key: "logout",
    icon: <VpnKeyOffIcon fontSize="small"></VpnKeyOffIcon>,
    route: "/logout",
    component: <Logout />,
  },  
];

export default routes;
