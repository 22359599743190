import MDTypography from "components/MDTypography";

export function aracTip (key,i,rutlar,aracTipleri){
  // console.log('key------',key);
  var hacim_percent = (rutlar[key].hacim / aracTipleri[i].hacim * 100).toFixed(0);
  var kg_percent    = (rutlar[key].kg / aracTipleri[i].kg * 100).toFixed(0);
  var hacim_style = '';
  if (hacim_percent >= 90) {
    hacim_style = 'error';
  } else if (hacim_percent >= 55) {
    hacim_style = 'warning';
  } else if (hacim_percent >= 50) {
    hacim_style = 'secondary'
  } else if (hacim_percent >= 25) {
    hacim_style = 'success';
  } else {
      hacim_style = 'success';
  }
  return <MDTypography variant="h6" key={i} mt={2} color={hacim_style}>
            {aracTipleri[i].image +" | HACIM : %" + hacim_percent +" | TONAJ : %" + kg_percent}
         </MDTypography>
}