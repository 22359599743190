import React, { useState, useEffect } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from '@mui/material/Unstable_Grid2';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import GroupDataGrid from './groupTableCollapse';

import { Item } from 'pages/common/constants';
import { useMaterialUIController } from "context";

const aracTip =(rutlar,aracTipleri,key,i) =>{
    // console.log('key------',key);
    var hacim_percent = (rutlar[key].hacim / aracTipleri[i].hacim * 100).toFixed(0);
    var kg_percent    = (rutlar[key].kg / aracTipleri[i].kg * 100).toFixed(0);
    var hacim_style = '';
    if (hacim_percent >= 90) {
      hacim_style = 'error';
    } else if (hacim_percent >= 55) {
      hacim_style = 'warning';
    } else if (hacim_percent >= 50) {
      hacim_style = 'secondary'
    } else if (hacim_percent >= 25) {
      hacim_style = 'success';
    } else {
        hacim_style = 'success';
    }
    return (
        <MDTypography variant="h6" key={i} mt={2} color={hacim_style}>
            {aracTipleri[i].image +" | HACIM : %" + hacim_percent +" | TONAJ : %" + kg_percent}
        </MDTypography>);
}

function Step1(props){
    const {activeRut,aracTipleri,araclar,rutlar,soforler,depolar,sevkedilecekler} = props;   
    // console.log('sevkedilecekler',sevkedilecekler) ;
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [aracTipItem, setAracTipItem] = useState('');
    const [plakaItem, setPlakaItem] = useState('');
    const [plakaOptions,setPlakaOptions] = useState([]);
    const [sofor1, setSofor1] = useState('');
    const [sofor2, setSofor2] = useState('');
    const [startDepo, setStartDepo] = useState('');    
    const [startDepoCoordinate, setStartDepoCoordinate] = useState({enlem:0,boylam:0,ad:''});    
    const [cariGrupluEvraklar,setCariGrupluEvraklar] = useState({});    
    const [gruplarItem,setGruplarItem] = useState([]);
    const [miktarlarItem,setMiktarlarItem] = useState({});

    useEffect(() => {                
        let gruplar=[];
        let miktarlar={};
        for (let i = 0; i < sevkedilecekler.length; i++) {      
            sevkedilecekler[i].sevkedilecek_miktar = sevkedilecekler[i].Miktar - sevkedilecekler[i].AracaYuklenenMiktar - sevkedilecekler[i].MusteriyeTeslimEdilenMiktar;
            
            if(!gruplar.includes(sevkedilecekler[i].Grup)){
                gruplar.push(sevkedilecekler[i].Grup);
                miktarlar[sevkedilecekler[i].Grup] = sevkedilecekler[i].sevkedilecek_miktar;
            }else{
                miktarlar[sevkedilecekler[i].Grup] += sevkedilecekler[i].sevkedilecek_miktar;
            }
        }
        setGruplarItem(gruplar);
        
        setMiktarlarItem(miktarlar);
             
        
        let cari_gruplu_evraklar = {}
        for (let k = 0; k < sevkedilecekler.length; k++) {
            try {
                cari_gruplu_evraklar[sevkedilecekler[k].CariKod].push(sevkedilecekler[k])
            } catch (err) {
                cari_gruplu_evraklar[sevkedilecekler[k].CariKod] = []
                cari_gruplu_evraklar[sevkedilecekler[k].CariKod].push(sevkedilecekler[k])
            }
        }
        setCariGrupluEvraklar(cari_gruplu_evraklar);
    }, []);
    

    const handleChangeAracTip = (event) => {
        setAracTipItem(event.target.value);
        setPlakaItem('');
        let tempplakaOptions= [];        
        if(aracTipItem !== ''){
            for (let i = 0; i < araclar.length; i++) {
                if (araclar[i].AraccTipi === aracTipItem) {
                    tempplakaOptions.push({'plaka':araclar[i].AracPlaka, 'kod':araclar[i].AracKodu});          
                } 
            }
        }
       // console.log(tempplakaOptions);
        setPlakaOptions(tempplakaOptions);        
    }
    const handleChangePlaka = (event) => {
        setPlakaItem(event.target.value);    
    }
    const handleChangeSofor1 = (event) => {
        setSofor1(event.target.value);    
    }
    const handleChangeSofor2 = (event) => {
    setSofor2(event.target.value);
    }
    const handleChangeStartDepo = (event) => {
        setStartDepo(event.target.value);   
        for(let i =0; i<depolar.length;i++) {            
            if(depolar[i].ad === event.target.value){
                // console.log(depolar[i].ad);
                setStartDepoCoordinate({enlem: depolar[i].enlem, boylam: depolar[i].boylam,ad:event.target.value});
            }
        }        
    }    
    //console.log('cariGrupluEvraklar',Object.keys(cariGrupluEvraklar).length );
    return (
        <MDBox mt={1} sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid sx={{ minWidth: 100 }} item xs={4} sm={4} md={6} key={1}>
                    <Item dark={darkMode ? 1 : 0}>
                        <Stack spacing={2}>                                        
                            <MDTypography variant="h6" color="dark"> Seçilen Rut: {activeRut+' ('+Object.keys(cariGrupluEvraklar).length+')'}</MDTypography>
                            <MDBox sx={{height:10}}></MDBox>                            
                            <FormControl id='frmAracTipi' fullWidth>
                            <InputLabel id="cars-select-label">
                            <MDTypography variant="body2" sx={{fontSize:14,fontWeight:600}} color="dark">Arac Tipi</MDTypography></InputLabel>
                            <Select style={{ marginTop: 1, marginLeft: 1, width:'100%',height:'50px' }}
                                labelId="cars-select-label"
                                id="regions-select"
                                value={aracTipItem}
                                label="Araç Tipi"
                                onChange={handleChangeAracTip}
                            >   
                            <MenuItem key= '-1' value='-1'></MenuItem>
                            {aracTipleri && aracTipleri.map((tip, index) => (
                                <MenuItem key= {index} value={aracTipleri[index].image}>
                                {aracTip (rutlar,aracTipleri,activeRut,index)}
                                </MenuItem>                                      
                                ))}                                            
                            </Select>
                            </FormControl>
                            <FormControl id='frmPlaka' fullWidth>
                                <InputLabel id="cars-Plaka-label">
                                <MDTypography variant="body2" sx={{fontSize:14,fontWeight:600}} color="dark">Plaka</MDTypography></InputLabel>
                                <Select style={{ marginTop: 1, marginLeft: 1, width:'100%',height:'50px' }}
                                labelId="cars-Plaka-label"
                                id="plaka-select"
                                value={plakaItem}
                                label="Plaka"
                                onChange={handleChangePlaka}
                                >                                              
                                {plakaOptions.map((opt,index)=>(                                    
                                    <MenuItem key= {index} value={opt.kod}>
                                        <MDTypography variant="h6" mt={2}>{opt.plaka}</MDTypography>
                                    </MenuItem>
                                ))
                                
                                }                    
                                </Select>
                            </FormControl>
                            <MDBox></MDBox>
                        </Stack>
                    </Item>
                </Grid>
                <Grid sx={{ minWidth: 100 }} item xs={4} sm={4} md={6} key={2}>
                    <Item dark={darkMode ? 1 : 0} >
                        <Stack spacing={2}>                                        
                            <FormControl id='frmSofor' fullWidth>
                            <InputLabel id="sofor1-select-label"><MDTypography variant="body2" sx={{fontSize:14,fontWeight:600}} color="dark">Şoför</MDTypography></InputLabel>
                            <Select style={{ marginTop: 1, marginLeft: 1, width:'100%',height:'50px' }}
                                labelId="cars-select-label"
                                id="regions-select"
                                value={sofor1}
                                label="Şoför"
                                onChange={handleChangeSofor1}
                            >                                               
                            {soforler && soforler.map((sofor, index) => (                                              
                                (sofor.kodu !== sofor2)?
                                <MenuItem key= {index} value={sofor.kodu}>
                                {sofor.Adi}
                                </MenuItem>
                                :null
                                ))}                                            
                            </Select>
                            </FormControl>
                            <FormControl id='frmSoforAssistant' fullWidth>
                                <InputLabel id="soforAssistant-label">
                                <MDTypography variant="body2" sx={{fontSize:14,fontWeight:600}} color="dark">
                                    Yardımcı Eleman</MDTypography></InputLabel>
                                <Select style={{ marginTop: 1, marginLeft: 1, width:'100%',height:'50px' }}
                                labelId="soforAssistant-label"
                                id="soforAssistant-select"
                                value={sofor2}
                                label="Yardımcı Eleman"
                                onChange={handleChangeSofor2}
                                >                                              
                                {soforler && soforler.map((sofor, index) => (
                                    (sofor.kodu !== sofor1)?
                                    <MenuItem key= {index} value={sofor.kodu}>
                                    {sofor.Adi}
                                    </MenuItem>
                                :null
                                ))}                      
                                </Select>
                            </FormControl>
                            <FormControl id='frmStartDepo' fullWidth>
                                <InputLabel id="startDepo-label"><MDTypography variant="body2" sx={{fontSize:14,fontWeight:600}} color="dark">
                                    Rut Başlangıç Deposu</MDTypography></InputLabel>
                                <Select style={{ marginTop: 1, marginLeft: 1, width:'100%',height:'50px' }}
                                labelId="startDepo-label"
                                id="startDepo-select"
                                value={startDepo}
                                label="Rut Başlangıç Deposu"
                                onChange={handleChangeStartDepo}
                                >                                              
                                {depolar && depolar.map((depo, index) => (
                                    //console.log('depolar',depolar)
                                    <MenuItem key= {index} value={depo.ad}>
                                    {depo.ad}
                                    </MenuItem>                                                
                                ))}                      
                                </Select>
                            </FormControl>
                            <MDBox sx={{height:1}}></MDBox>                            
                        </Stack>
                    </Item>
                </Grid>
                {/* <Grid sx={{ minWidth: 100 }} item xs={4} sm={4} md={6} key={3}>                    
                    <ReportCount cari_gruplu_evraklar={cariGrupluEvraklar}  v={allDesi} kg={allKilo} count={allCount}  />                    
                </Grid> */}
                {/* <Grid sx={{ minWidth: 100 }} item xs={4} sm={4} md={6} key={4}>
                    <ReportGroup groupEticket={gruplarItem} groupValue={miktarlarItem} />
                </Grid> */}
                <Grid sx={{ minWidth: 100 }} item xs={4} sm={8} md={12} key={5}>
                    <Item dark={darkMode ? 1 : 0} >
                         <GroupDataGrid activeRut={activeRut} cari_gruplu_evraklar={cariGrupluEvraklar} groupEticket={gruplarItem} groupValue={miktarlarItem} startDepoCoordinate={startDepoCoordinate}/>
                    </Item>
                </Grid>
            </Grid>
        </MDBox>     
    );
}

export default Step1;

