import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import MDBox from "components/MDBox";
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import MDTypography from "components/MDTypography";
import MDButton from 'components/MDButton';
import MapModal from './mapDialog'; 


export default function MapReport({activeItem,allDistance,allDuration,mapResult}) {        
    const [showModal,setShowModal] = useState(false);
    const handleMapView = () =>{
        setShowModal(!showModal);
    }    
    const toggle = () => {
        setShowModal(!showModal);        
    };
    
    
    return (
        <>
        <MDBox sx={{ flexGrow: 2,height:80}}>            
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>                
                <Grid xs={2} sm={8} md={4} container justifyContent="center" alignItems="center">
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Icon fontSize='medium'>access_alarm</Icon>
                        <MDTypography variant="body1" sx={{fontSize:16, fontWeight:600}}> Toplam Sürec: {allDuration} </MDTypography>
                    </Stack>
                </Grid>  
                <Grid xs={2} sm={8} md={3} container justifyContent="center" alignItems="center">
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Icon fontSize='medium'>route</Icon>
                        <MDTypography variant="body1" sx={{fontSize:16, fontWeight:600}}> Toplam Mesafe: {allDistance} </MDTypography>
                    </Stack>
                </Grid>               
                <Grid xs={4} sm={8} md={5} >
                    {mapResult?
                        <MDButton
                            color="info"
                            variant="gradient"
                            onClick={()=>handleMapView()}
                            // disabled={disabled}
                                                                
                            >
                            Haritada Görüntüle
                        </MDButton>
                        : <MDTypography variant="body1" sx={{fontSize:14, fontWeight:600}}>Şimdilik 25 ten fazla nokta harita da gösterilemez!  </MDTypography>
                    }
                </Grid>
            </Grid>  
        </MDBox>
        {showModal ? (
            <MapModal                
                places={mapResult}                
                toggle={toggle}                
            />
            ) : null}
        </>
    );
}