import React,{useState,useEffect} from 'react';
import { styled } from '@mui/material/styles';
import {API_URL} from 'pages/common/constants';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import MDButton from "components/MDButton";
import Swal from 'sweetalert2';
import MDTypography from "components/MDTypography";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import axios from 'axios';
import './custom.css';
import MDBox from 'components/MDBox';
import Stack from '@mui/material/Stack';


const url = API_URL + "/api/cariler";
const url_save = API_URL + "/api/query";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export default function Searchers({center}) {
  const [customers,setCustomers] = useState([]);
  const [suggestions,setSuggestions] = useState([]);  
  const [text,setText] = useState('');
  const [customerSelected,setCustomerSelected] = useState(false);
  const [activeCustomer,setActiveCustomer] = useState({});

  useEffect(()=>{
    const loadcustomers = async()=>{      
      if(text.length>5){
        const response2 = await axios.post(url,{arama: text.toUpperCase() });
        setCustomers(response2.data.data);
      }      
    }
    loadcustomers();
  },[text]);
  const onSuggestHandler = (event,suggestion) =>{
    // console.log('text',suggestion.text)
    setText(suggestion.text);
    setCustomerSelected(true);
    setActiveCustomer(suggestion);
    setSuggestions([]);
  }
  const onChangeHandler = (input_text) =>{
    if(input_text.length<text.length){
      setText('');
      setSuggestions([]);
      setCustomerSelected(false);
      setActiveCustomer({});
    }
    else{
      let matches= [];
      if(input_text.length>5){
        matches = customers.filter(customer=>{
          const regex = new RegExp(`${input_text}`,"gi");
          return customer.text.match(regex)
        })
      }
      // console.log('matches',matches);
      setSuggestions(matches);
      setText(input_text);
    }
  }
  const handleSaveLocation = () => {
    if(customerSelected){
      var data = {
        carikod: activeCustomer.kod,
        latitude: center.lat,
        longitude: center.lng
      }
      
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',dataType:'json' },
        body: JSON.stringify({ "tip": 21, "data": data })
      };
      console.log(url_save,requestOptions);
      fetch(url_save, requestOptions)
          .then(response => response.json())
          .then(data => {
            Swal.fire({
              title: 'Başarılı',
              html: 'Lokasyon Kayd etmesi başsrılı oldu!',
              icon: 'success'
          })
  
      });
      setText('');
      setSuggestions([]);
      setCustomerSelected(false);
      setActiveCustomer({});
    }
    else{
      Swal.fire({
        title: 'Dikkat!',
        text: 'Lütfen Bir Müşteri Seçiniz!',
        icon: 'error',
        confirmButtonText: 'Olur'
      });      
    }
  }
 
  return (    
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>      
        <Grid item xs={4} sm={8} md={12}>        
            <FormControl id='frmSearch' fullWidth>
              <InputLabel htmlFor="demo-customized-textbox" id="search-select-label">
                <MDTypography variant="body2" sx={{marginTop:'20px',fontSize:14,fontWeight:600}} color="dark">Lütfen Müşterinizi Seçiniz</MDTypography>
              </InputLabel>
              <BootstrapInput id="demo-customized-textbox" 
                onChange={e=>onChangeHandler(e.target.value)}
                value={text} 
                onBlur={()=>{
                  setTimeout(()=>{
                    setSuggestions([]);
                  },100);
                }}
              />
              {suggestions && suggestions.map((suggestion,i)=>
                <MDBox key={i}   
                  onClick={event => onSuggestHandler(event, suggestion)}
                  sx={{          
                    display: 'flex',          
                    height: 60,
                    cursor: 'pointer',
                    borderRight: '1px solid black',    
                    borderLeft: '1px solid black', 
                    borderBottom: '1px solid black',  
                                   
                    '&:hover': {
                      backgroundColor: 'info.main',
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  
                  >{suggestion.text}</MDBox>
              )}
            </FormControl>        
        </Grid>
        <Grid item xs={4} sm={8} md={12}>  
        {customerSelected  &&
          <MDBox sx={{ width: '100%' }}>
            <Stack spacing={1}>
              <MDBox px={3} mb={1}  display="flex">
                <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                  Unvan:  &nbsp; {activeCustomer.unvan}
                </MDTypography>            
              </MDBox>              
              <MDBox px={3} mb={1}  display="flex">
                <MDTypography variant="button" color="text" fontWeight="medium">
                  Adres:  &nbsp; {activeCustomer.il +', '+activeCustomer.ilce+', '+ activeCustomer.adres}
                </MDTypography>
              </MDBox>
              <MDBox px={3} mb={1}  display="flex">
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Email: &nbsp; {activeCustomer.mail }
                </MDTypography>            
              </MDBox>
              <MDBox px={3} mb={1}  display="flex">
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Tel: &nbsp; {activeCustomer.tel }
                </MDTypography>            
              </MDBox>
              <MDBox px={3} mb={1}  display="flex">
                <MDTypography variant="button" color="text" fontWeight="medium">
                Kayıtlı konum, &nbsp; {'enlem: '+ activeCustomer.enlem + ', boylam: '+activeCustomer.boylam }
                </MDTypography>
              </MDBox>
              <MDBox px={30} mb={1}  display="flex">
                <MDButton style={{ width:'150px',height:'50px',marginTop:'20px' }} 
                  onClick={handleSaveLocation} variant="contained" color="success" >Kayd et</MDButton>
              </MDBox>
            </Stack>

          </MDBox>
        }      
        </Grid>      
      </Grid>
    
  );
}

